<div id="logo">
  <img src="/assets/images/tokyo-logo.png">
</div>

<div *ngFor="let section of sections" class="section-container">
  <div *ngIf="!section.featured">
    <a [routerLink]="['/section', section.id]">
      <div class="section" [className]="section.featured ? 'featured section' : 'section'"
           [ngStyle]="backgroundImage(section.id)">
        <h2>{{section.name}}</h2>
      </div>
    </a>
  </div>
  <div *ngIf="section.featured">
    <div class="section" [className]="section.featured ? 'featured section' : 'section'"
         [ngStyle]="backgroundImage(section.id)">
      <h2>{{section.name}}</h2>
      <app-featured-section></app-featured-section>
    </div>
  </div>

</div>
