<header>
  <a [routerLink]="'/'" class="back-button">
    <i class="fas fa-arrow-left"></i>
  </a>

  {{ title }}
</header>

<div class="container" *ngIf="viewMode === 'grid'">
  <div class="row">
    <div class="col" *ngFor="let element of elements">
      <div class="element" [ngStyle]="backgroundImage(element.id)" (click)="openModal(element)">
        <span class="id">{{ element.id }}</span>

        <div class="info">
          <span class="name">{{ element.name }}</span>

          <span class="price">{{ element.price }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="list" *ngIf="viewMode === 'list'">
  <div class="list-element" *ngFor="let element of elements">
    <div class="content" (click)="openModal(element)">
      <span class="id">{{ element.id }}</span>
      <span class="name">{{ element.name }}</span>
      <span class="price">{{ element.price }}</span>
    </div>
  </div>
</div>
