<div id="modal-content-wrapper">
  <div id="close-button-row">
    <span id="close-button" (click)="closeDialog()">&times;</span>
  </div>
  <header id="modal-header">
    <h1 id="modal-title">{{ modalData.title }}</h1>
  </header>
  <section id="modal-body">
    <div *ngIf="modalData.image">
      <img [src]="modalData.image">
    </div>

    <div [innerHTML]="modalData.description"></div>
  </section>
</div>
