<div class="container">
  <h2>All you can eat</h2>
  <h3>LUN - GIO</h3>

  <div class="row">
    <div class="col">
      <h5>Pranzo</h5>
      <h4>17€</h4>
    </div>
  </div>

  <h2>All you can eat & drink</h2>
  <h3>LUN - DOM</h3>
  <div class="row">
    <div class="col">
      <h5>Pranzo</h5>
      <h4>20€</h4>
    </div>
  </div>
  <a class="btn-info" (click)="openModal()">Regolamento</a>
</div>
