import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-featured-section',
  templateUrl: './featured-section.component.html',
  styleUrls: ['./featured-section.component.scss']
})
export class FeaturedSectionComponent implements OnInit {

  constructor(public matDialog: MatDialog) { }

  ngOnInit(): void {
  }

  openModal(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '90vw';
    dialogConfig.height = '80vh';
    dialogConfig.data = {
      title: 'Regolamento',
      description: '<ol>\n' +
        '  <li>Le offerte sono valide solo se partecipano tutti gli adulti al tavolo</li>\n' +
        '  <li>Bambini: gratis fino a 3 anni / metà prezzo da 4 a 8 anni / prezzo pieno da 9 anni in poi</li>\n' +
        '  <li>La penale sugli avanzi è di 1,50€ al pezzo di sushi, al prezzo intero per la cucina orientale, sarà permesso il take away degli avanzi.</li>\n' +
        '  <li>È vietato nascondere in qualsiasi modo eventuali avanzi non consumati per evitare la penale.</li>\n' +
        '</ol>' +
        '<p>Frutta, dolci e amari non sono compresi</p>'
    };
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }
}
