import { Component, OnInit } from '@angular/core';
import { MenuService } from '../menu.service';
import { Section } from '../section'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  sections: Section[];

  constructor(private menuService: MenuService) { }

  ngOnInit(): void {
    this.getSections();
  }

  getSections(): void {
    this.menuService.getSections().subscribe(sections => this.sections = sections);;
  }

  backgroundImage(id) {
    return {
      'background-image': 'url(/assets/images/sections/'+id+'.jpg)'
    };
  }

}
