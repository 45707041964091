import {Component, OnInit} from '@angular/core';

import {ActivatedRoute} from '@angular/router';

import {MenuService} from '../menu.service';
import {Section} from '../section';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ModalComponent} from '../modal/modal.component';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {
  sectionId: number;
  elements: object[];
  title: string;
  viewMode: string;

  constructor(private menuService: MenuService,
              private route: ActivatedRoute,
              public matDialog: MatDialog,
              private httpClient: HttpClient) {
  }

  ngOnInit(): void {
    this.sectionId = +this.route.snapshot.paramMap.get('id');
    this.getSection();
  }

  getSection(): void {
    this.menuService.getSections().subscribe(sections => {
      sections.forEach((section) => {
        if (section.id === this.sectionId) {
          this.elements = section.elements;
          this.title = section.name;
          this.viewMode = section.viewMode;
        }
      });
    });
  }

  backgroundImage(id) {
    return {
      'background-image': 'url(/assets/images/elements/' + id + '.jpg)'
    };
  }

  openModal(element): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.panelClass = 'element-info-modal';
    dialogConfig.width = '90vw';
    dialogConfig.height = '80vh';
    dialogConfig.data = {
      title: element.name,
      description: '<p>' + element.description + '</p>',
    };

    this.fileExists('./assets/images/elements/' + element.id + '.jpg').subscribe((exists) => {
      if (exists) {
        dialogConfig.data.image = '/assets/images/elements/' + element.id + '.jpg';
      }

      const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
    });
  }

  public fileExists(url: string): Observable<boolean> {
    return this.httpClient.get(url, {observe: 'response', responseType: 'blob'})
      .pipe(
        map(response => {
          return true;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

}
